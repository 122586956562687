.invalid{
    color: red;
    text-align: left;
}
.center{
   
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    text-align: center;
    /*  //text-align: justify; */
}
.headerMT{
    margin-top: 100px;
}
.mt150{
    margin-top: 150px;
}
.mt100{
    margin-top: 100px;
}
.headerMTcr{
    margin-top: 200px;
}
.ml-minus{
    margin-left: -4.5%;
}
.Card{
    margin-left: 35%;
   /*  border: 2px solid  rgb(156, 186, 167); */
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    /* padding: 5px; */
    width: 450px;
    height: auto;
    background-color: rgb(166, 224, 167);
    text-align: left;
    border-radius: 10px;
   
}
.mlauto{
    margin-left: 60%;
}
.text{
    font-size: medium;
    font-weight: bold;
}
.txtmarginL{
    margin-top: 20%;
}
.CFVcard{
    margin-left: 35%;
    /*  border: 2px solid  rgb(156, 186, 167); */
     /* border-top-color: black; */
     /* //border-top-color: blue; */
     /* padding: 5px; */
     width: 600px;
     height: 300px;
     background-color: rgb(4, 136, 6);
     text-align: left;
     border-radius: 20px;
}
.CFVlabel{
    font-size: x-large;
    font-weight: bolder;
    color: rgb(15, 202, 118);
}
.grentext{
    font-size: medium;
    /* font-weight: bolder; */
    color: rgb(15, 202, 118);
}
.continueRcard{
    margin-left: 30%;
     border: 1px solid  rgb(156, 186, 167);
     /* border-top-color: black; */
     /* //border-top-color: blue; */
     /* padding: 5px; */
    width: 500px;
     height: auto;
     background-color: rgb(213, 246, 208);
     /* text-align: left; */
     border-radius: 10px;
}
.btnBorder{
    border-radius: 20px;
}
.labeltext{
    color: rgb(149, 152, 155);
}
.labelML{
    margin-left: 30%;
}
.CRcard-one{
    margin-top: 50px;
    background-color: rgb(213, 246, 208);
    border: 1px solid  rgb(156, 186, 167);
    width: 100%;
    height: 820px;
    background-color: rgb(213, 246, 208);
     /* text-align: left; */
     border-radius: 5px;
     margin-left: 5%;
}
.CRcard-view{
    margin-top: 50px;
    background-color: rgb(213, 246, 208);
    border: 1px solid  rgb(156, 186, 167);
    width: 100%;
    height: auto;
    background-color: rgb(213, 246, 208);
     /* text-align: left; */
     border-radius: 5px;
     margin-left: 5%;
}
.CRcard-two{
    margin-top: 50px;
    background-color: rgb(213, 246, 208);
    border: 1px solid  rgb(156, 186, 167);
    width: 95%;
    height: auto;
    background-color: rgb(213, 246, 208);
     /* text-align: left; */
     border-radius: 5px;
     margin-left: 5%;
    /*  margin-right: 1%; */
}
.ccpLabel{
    margin-left: 5%;
    font-size: x-large;
    font-weight: bolder;
    color: rgb(28, 134, 86);
}
.companyAddressLabel{
    font-size: medium;
    font-weight: bold;
}
.ml5percent{
    margin-left: 7%;
}
.ml2percent{
    margin-left: 2%;
}
.asd{margin-right: 20%;}
.margincheck{
    margin-left: 2%;
}
.checktxt{
    font-size: smaller;
    font-weight: lighter;
}
.successsubmited{
    color: rgb(15, 202, 118);
}
.alerthieght{
    height: 130px;
}
.alerthieght1{
    height: 50px;
}

/* application form create */
.display-block {
    display: block;
}
.display-none {
    display: none;
}
.photoAFC{
    /* // color: black; */
    border: 2px solid  black;
    /* border-top-color: black; */
    /* //border-top-color: blue; */
    padding: 5px;
    width: 200px;
    height: 200px;
    box-sizing: content-box;
    background-color: rgb(255, 255, 255);
}
.photoAdjust{
    margin-top: -6%;
}
.aa{
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
}
.aaa{
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
    justify-items: center;
    text-align: center;
   /*  //text-align: justify; */
}
.tabColor{
   /*  background-color: rgb(8, 139, 30);; */
   background-color: green
}
.marleft{
    margin-right: auto;
}
.btnviewCompany{
    width: 100%;
    align-items: flex-end;
    align-self: flex-end;
    align-content: flex-end;
    justify-items: flex-end;
    justify-self: flex-end;
    justify-content: flex-end;
}
.appFormBackground{
    background-color: rgb(58, 160, 82);
   /*  color: blue; */
}
.appFormBackground1{
    background-color: rgb(237, 244, 240);
   /*  color: blue; */
}
.checkbbb{
    border-right-color: 2px solid black ;
}


@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
}

.App .openModalBtn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}
body {
  padding: 0;
  margin: 0;
}
.modalBackground {
    width: auto;
    height: auto;
    background-color: rgba(200, 200, 200);
    position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer {
    width: auto;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    /* flex-direction: column; */
    padding: 25px;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }
