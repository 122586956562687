//
// menu.scss
//

/********************TOP-BAR********************/
.top-bar {
    background-color:var(--#{$prefix}topnav-bg);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1040;
    
}

.topbar-social-menu {
    .social-link {
        display: inline-block;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: var(--#{$prefix}dark);
        transition: all 0.5s ease;

        &:hover {
            color: $success;
        }
    }
}

.language-switch {
    .btn {
        &:focus {
            box-shadow: none;
        }
        &:active,&.show{
            border-color: transparent;
        }
    }
}

/**********************NAVBAR***********************/

.navbar {
    transition: all 0.5s ease;
    padding: 0;
    //margin-top: 44px;
    //background-color: var(--#{$prefix}secondary-bg);
    //box-shadow: var(--#{$prefix}box-shadow);
    
    // z-index: 1;
    
    //background-image: linear-gradient(to right,rgba(13, 88, 17, 0.986) , rgb(8, 139, 30));
    //background: linear-gradient(0deg, rgba(84,87,87,1) 0%, rgba(2,107,12,1) 90%);
    background-color: rgb(8, 139, 30) !important;
    //background: linear-gradient(0deg, rgba(84,87,87,1) 0%, rgba(6,112,16,1) 100%);
    //box-shadow: 3px 3px 6px #333;
    color:#FFF;
    //box-shadow: var(--#{$prefix}box-shadow);
    
    .navbar-nav {
        .nav-link {
            //color: var(--#{$prefix}dark);
            color: #FFF;
            font-size: 13px;
            background-color: transparent;
            padding: 23px 18px;
            text-transform: uppercase;
            transition: all 0.3s ease;
            position: relative;
            font-weight: 600;

            &:hover,
            &:active,
            &.active {
                //color: var(--#{$prefix}primary);
                //color: #0066FF;
                color: #FFFF00;
                font-weight: bold;
            }
        }
        .nav-item {
            &.active {
                .nav-link { 
                    //color: var(--#{$prefix}primary);
                    color: #FFFF00;
                }
            }
        }
    }

    .navbar-toggler {
        padding: 0px;
        border: none;
        font-size: 24px;

        &:focus {
            box-shadow: none;
        }
    }

    .header-item {
        //color: var(--#{$prefix}dark);
        color: #0066FF;
        transition: all 0.5s ease;
        font-weight: bold;
    }
}

.navbar-brand {
    .logo-dark {
        display: inline-block;
    }

    .logo-light {
        display: none;
    }
}

.header-item {
    height: 65px;
    line-height: 65px;
    border: 0;
    border-radius: 0;
    display: inline-block;
}

.notification-wrapper {
    max-height: 250px;
    overflow: hidden scroll;

    .notification-item {
        padding: 15px;
        transition: all 0.5s ease;

        &:hover {
            background-color: var(--#{$prefix}light);
        }
    }

}

.notification-header {
    padding: 1rem;
}

.notification-footer {
    padding: 10px;
}

.noti-icon {
    .count {
        position: absolute;
        top: 15px;
        right: -6px;
        margin-top: -3px;
        margin-right: -5px;
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        background-color: $danger;
        border-radius: 40px;
        color: $white;
        border: 2px solid var(--#{$prefix}border-color-translucent);
    }
}

//nav-sticy
.nav-sticky {
    &.navbar {
        //background: var(--#{$prefix}secondary-bg);
        margin-top: 0px;
        box-shadow: 0px 3px 10px 0px rgba($dark, 0.08);
        //background-image: linear-gradient(to right,rgba(13, 88, 17, 0.986) , rgb(8, 139, 30));
        //background-image: linear-gradient(to right,rgba(13, 88, 17, 0.986) , rgb(8, 139, 30));
        //background: linear-gradient(0deg, rgba(84,87,87,1) 0%, rgba(2,107,12,1) 90%);
        background-color: rgb(8, 139, 30) !important;
        //background: linear-gradient(0deg, rgba(84,87,87,1) 0%, rgba(6,112,16,1) 100%);
        //box-shadow: 3px 3px 6px #333;
        color:#FFF;
    }

    .header-item {
        //color: var(--#{$prefix}dark);
        color: #0066FF;
    }
}

@media (max-width: 991.98px) {
    .navbar {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    padding: 8px 15px;
                }
            }
        }
    }

    .navbar-collapse {
        max-height: 385px;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        left: 0px;
        right: 0px;
        top: 65px;
        background: $white;
        padding-bottom: 10px;
        padding-left: 25px;
        box-shadow: 0px 3px 10px 0px rgba($dark, 0.08);
    }
}

.arrow-down {
    display: inline-block;

    &:after {
        border: 0;
        left: auto;
        content: "\F0140";
        font-family: 'Material Design Icons';
        top: 35%;
        right: 28px;
        font-size: 14px;
        margin-left: 2px;
        transform: translateY(-50%);
        transition: all .3s ease-out;
    }

    @media(max-width: 991.98px) {
        &::after {
            right: 30px;
            position: absolute;
        }
    }
}

@media (max-width: 767.98px) {
    .navbar {
        margin-top: 0px;
    }

    .top-bar {
        display: none;
    }
}


/*****************dark-mode******************/

body[data-bs-theme='dark'] {
    .navbar {
        background-color: var(--#{$prefix}tertiary-bg);
    }

    .nav-sticky {
        &.navbar {
            background-color: var(--#{$prefix}tertiary-bg);
        }
    }

    .navbar-brand {
        .logo-dark {
            display: none;
        }

        .logo-light {
            display: inline-block;
        }
    }

}

@media (max-width: 991.98px) {

    .navbar-collapse {
        background-color: $gray-900;
        // box-shadow: 0px 3px 10px 0px rgba($gray-dark-600, 0.08);
    }
}

.companyRegisterClass{
    //background-color: rgb(213, 246, 208);
    background-color: #F0FEED;
    color:#000;
    border-color: rgb(8, 139, 30) !important;
    border-width : 3px;
    border-radius: 10px;
    font-weight: bold;
}

.otpStyle{
    background-color: #F0FEED;
    color:#000;
    border-color: rgb(8, 139, 30) !important;
    border-width : 3px;
    border-radius: 10px;
}

.noser-button-md{
  min-width: 90px !important;
}

.noser-button-lg{
  min-width: 190px !important;
}


input[type=checkbox] {
    width: 15px;
    height: 15px;
  }
.customcheck {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
// .customcheck input[type="checkbox"]:checked ~ {
//     background-color: #02cf32;
//     border-radius: 5px;
// }

.table tr td{
    padding-top: 2px!important;
    padding-bottom: 2px!important;
    cursor: pointer;
    background-color: #F0FEED;
    /*white-space: nowrap!important;*/
  }
  .table th{
    padding-top: 2px!important;
    padding-bottom: 2px!important;
    background-color:  rgb(8, 139, 30);
    color:#fff !important;
    white-space: normal;
    border: 1pt solid #DEE2E6 !important;
  }

  .card-border-color-custom{
    border-color: #088B1E;
    border-width: 3px;
  }

  .textInputCustomStyle{
    border-color: rgb(42, 218, 71);
    background-color: transparent;
  }


  .btn-hover-style{
    //background-color: rgb(8, 139, 30) !important;
    //background-color: rgb(8, 139, 30) !important;
    background-color: #088B1E;
    border-color: #ddd8d8;
    width: 435px;
  }
  
  .btn-hover-style:hover{
  //background-color: rgb(8, 139, 30) !important;
  //background-color: rgb(8, 139, 30) !important;
  background-color: #088B1E;
  border-color: #ddd8d8;
  }
  